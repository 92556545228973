<template>
  <div class="layoutContent">
    <no-data
     
      v-if="isNoData"
     
      :desc="noDataStr"
      :showpic="
        getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
      "
    />
    <div v-else>
      <home-title
        :description="SettingMsg"
        :showpic="
          getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
        "
      />
      <k-alert-msg ref="lblMsg" />
      <div class="contentParty">
        <training-nav />
        <div id="tooltipTraining" />
        <div v-show="requiredTotalCount > 0">
          <div class="cardHead">
            {{ $g("requiredCourses") }}
          </div>
          <div class="cardBody">
            <training-class-wap
              :fields="rfields"
              :items="tableWapData1"
              :go-view="goView"
              :click-button="clickButton"
              :handle-times="handleTimes"
              :handle-button-name="handleButtonName"
              :handle-button-permission-code="handleButtonPermissionCode"
            />
            <konn-grid
              ref="selectableTable1"
              :data-source="dataSource"
              :has-check-box="false"
              :get-condition="() => getCondition(true)"
              :has-action="hasAction"
              :has-pager="false"
              :fields="rfields"
              :grid-sort-by="defaultSortField"
              @fields-get="onFieldsGet"
              @items-get="allGet1"
              class="wapTableHide"
              @total-changed="onTotalChanged1"
            >
              <template #cell(trainingSection)="row">
                <div v-if="$has(PW_Worker_Portal_Code.Training.ViewCourseInfo)">
                  <k-link
                    @click="goView(row.item)"
                    v-if="row.value"
                  >
                    {{ row.value }}
                  </k-link>
                </div>
                <div v-else>
                  {{ row.value }}
                </div>
              </template>
              <template #cell(positions)="row">
                <div
                  v-for="(item, index) in row.value"
                  :key="index"
                >
                  {{ item }}
                </div>
              </template>
              <template #cell(description)="row">
                <div
                  class="lineClamp"
                  v-b-tooltip="{
                    placement: 'bottom',
                    trigger: 'hover',
                    title: handleRow(row.item),
                    boundary: 'scrollParent',
                    container: 'tooltipTraining',
                  }"
                >
                  {{ handleRow(row.item) }}
                </div>
              </template>
              <template #cell(trainingDatesTimes)="row">
                <div
                  v-for="(item, index) in handleTimes(row.value)"
                  :key="index"
                >
                  {{ item }}
                </div>
              </template>
              <template #cell(actions)="row">
                <div class="d-flex flex-column">
                  <k-button
                    @click="clickButton(button, row.item, 1)"
                    v-for="button in row.item.buttonDisplay"
                    v-show="
                      button.visible && handleButtonPermissionCode(button.name)
                    "
                    :key="button.name"
                    class="trainBtn"
                    variant="none"
                    :class="classType(button.name)"
                  >
                    {{ handleButtonName(button.name) }}
                  </k-button>
                </div>
              </template>
            </konn-grid>
          </div>
        </div>
        <div v-show="optionalTotalCount > 0">
          <div class="cardHead">
            {{ $g("optionalCourses") }}
          </div>
          <div class="cardBody">
            <training-class-wap
              :fields="ofields"
              :items="tableWapData2"
              :go-view="goView"
              :click-button="clickButton"
              :handle-times="handleTimes"
              :handle-button-name="handleButtonName"
              :handle-button-permission-code="handleButtonPermissionCode"
            />
            <konn-grid
              ref="selectableTable2"
              :data-source="dataSource"
              :has-check-box="false"
              :get-condition="() => getCondition(false)"
              :has-action="hasAction"
              :has-pager="false"
              :fields="ofields"
              :grid-sort-by="defaultSortField"
              @fields-get="onFieldsGet"
              @items-get="allGet2"
              class="wapTableHide"
              @total-changed="onTotalChanged2"
            >
              <template #cell(trainingSection)="row">
                <div v-if="$has(PW_Worker_Portal_Code.Training.ViewCourseInfo)">
                  <k-link
                    @click="goView(row.item)"
                    v-if="row.value"
                  >
                    {{ row.value }}
                  </k-link>
                </div>
                <div v-else>
                  {{ row.value }}
                </div>
              </template>
              <template #cell(description)="row">
                <div
                  class="lineClamp"
                  v-b-tooltip="{
                    placement: 'bottom',
                    trigger: 'hover',
                    title: handleRow(row.item),
                    boundary: 'scrollParent',
                    container: 'tooltipTraining',
                  }"
                >
                  {{ handleRow(row.item) }}
                </div>
              </template>
              <template #cell(positions)="row">
                <div
                  v-for="(item, index) in row.value"
                  :key="index"
                >
                  {{ item }}
                </div>
              </template>
              <template #cell(trainingDatesTimes)="row">
                <div
                  v-for="(item, index) in handleTimes(row.value)"
                  :key="index"
                >
                  {{ item }}
                </div>
              </template>
              <template #cell(actions)="row">
                <div class="d-flex flex-column">
                  <k-button
                    @click="clickButton(button, row.item, 2)"
                    v-for="button in row.item.buttonDisplay"
                    v-show="
                      button.visible && handleButtonPermissionCode(button.name)
                    "
                    :key="button.name"
                    class="trainBtn"
                    variant="none"
                    :class="classType(button.name)"
                  >
                    {{ handleButtonName(button.name) }}
                  </k-button>
                </div>
              </template>
            </konn-grid>
          </div>
        </div>
      </div>
    </div>
    <k-popwindow
      id="selectReason"
      @ok="selectReasonComplete"
      :before-close="onSelectReasonBeforeClose"
      :check-selected="false"
      :title="getReasonTitle()"
    >
      <SelectReason
        ref="selectReason"
        :func="curFunc"
        :title="getReasonTitle(1)"
      />
    </k-popwindow>
    <sign-up-or-reschedule
      :refs="$refs"
      :params="selectParams"
      :courses-type="coursesType"
    />
  </div>
</template>

<script>
import {
  getCoursesPage,
  getAcceptConfig,
  getDeclineConfig,
  getWithdrawConfig,
  setCoursesStatus,
  isNoClassData,
} from "../../api/training";
import { getPortalPageSetting } from "@/api/home";
import {
  saveAlert,
  repResult,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import trainingNav from "./components/training-nav.vue";
import HomeItem from "../components/home-item.vue";
import {
  EnumButtonDisplay,
  EnumTrainingConfirmStatus,
} from "@/utils/enums/enumTraining";

import SelectReason from "./components/selectReason.vue";
import SignUpOrReschedule from "./components/signUpOrReschedule.vue";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import HomeTitle from "@/views/components/homeTitle";
import NoData from "./components/noData.vue";
import trainingClassWap from "./components/training-class-wap.vue";
import { PW_Worker_Portal_Code } from "../../router/permissionCode";
export default {
  components: {
    trainingNav,
    SelectReason,
    SignUpOrReschedule,
    HomeItem,
    HomeTitle,
    NoData,
    trainingClassWap,
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      coursesType: 1,
      hasAction: true,
      PW_Worker_Portal_Code,
      requiredTotalCount: 0,
      optionalTotalCount: 0,
      isNoData: false,
      noDataStr: "",
      EnumButtonDisplay,
      curFunc: {},
      selectItem: {},
      selectParams: null,
      acceptFunc: {},
      rejectFunc: {},
      withdrawFunc: {},
      confirmStatus: 0,
      isSignUp: true,
      selected: [],
      defaultSortField: "name",
      rfields: [
        { key: "electionTitle", label: "election" },
        { key: "positions", label: "position", width: "200px" },
        { key: "classType", label: "classType", width: "200px" },
        { key: "description", label: "description", width: "180px" },
        { key: "trainingSection", label: "trainingSection", width: "150px" },
        { key: "trainingType", label: "trainingType", width: "150px" },
        {
          key: "trainingDatesTimes",
          label: "trainingDates/Times",
          width: "250px",
        },
        { key: "completedAttended", label: "completed/Attended" },
      ],
      ofields: [
        { key: "electionTitle", label: "election" },
        { key: "positions", label: "position", width: "200px" },
        { key: "classType", label: "classType", width: "200px" },
        { key: "description", label: "description", width: "180px" },
        { key: "trainingSection", label: "trainingSection", width: "150px" },
        { key: "trainingType", label: "trainingType", width: "150px" },
        {
          key: "trainingDatesTimes",
          label: "trainingDates/Times",
          width: "250px",
        },
        { key: "completedAttended", label: "completed/Attended" },
      ],
      buttonNames: new Map(),
      SettingMsg: "",
      tableWapData1: null,
      tableWapData2: null,
    };
  },
  computed: {
    dataSource: function () {
      return getCoursesPage;
    },
  },
  created() {
    this.buttonNames.set(EnumButtonDisplay.IsShowAccept, "accept");
    this.buttonNames.set(EnumButtonDisplay.IsSignUp, "signUp");
    this.buttonNames.set(EnumButtonDisplay.IsReschedule, "reschedule");
    this.buttonNames.set(EnumButtonDisplay.IsShowReject, "decline");
    this.buttonNames.set(EnumButtonDisplay.IsShowWithdraw, "withdraw");
    this.buttonNames.set(EnumButtonDisplay.IsViewCourseInfo, "viewCourseInfo");
  },
  methods: {
    onTotalChanged1(total) {
      this.requiredTotalCount = total;
    },
    onTotalChanged2(total) {
      this.optionalTotalCount = total;
    },
    getReasonTitle(mark) {
      switch (this.curFunc.confirmTrainingReasonType) {
        case EnumTrainingConfirmStatus.Accepted:
          return mark ? this.$g("acceptReason") : this.$g("accept");
        case EnumTrainingConfirmStatus.NoAccepted:
          return mark ? this.$g("declineReason") : this.$g("decline");
        case EnumTrainingConfirmStatus.Withdraw:
          return mark ? this.$g("withdrawReason") : this.$g("withdraw");
        default:
          return this.curFunc.title;
      }
    },
    handleButtonPermissionCode(value) {
      if (value) {
        if (parseInt(value) == EnumButtonDisplay.IsShowAccept) {
          return this.$has(this.PW_Worker_Portal_Code.Training.Accept);
        } else if (parseInt(value) == EnumButtonDisplay.IsSignUp) {
          return this.$has(this.PW_Worker_Portal_Code.Training.SignUp);
        } else if (parseInt(value) == EnumButtonDisplay.IsReschedule) {
          return this.$has(this.PW_Worker_Portal_Code.Training.Reschedule);
        } else if (parseInt(value) == EnumButtonDisplay.IsShowReject) {
          return this.$has(this.PW_Worker_Portal_Code.Training.Decline);
        } else if (parseInt(value) == EnumButtonDisplay.IsShowWithdraw) {
          return this.$has(this.PW_Worker_Portal_Code.Training.Withdraw);
        } else if (parseInt(value) == EnumButtonDisplay.IsViewCourseInfo) {
          return this.$has(this.PW_Worker_Portal_Code.Training.ViewCourseInfo);
        } else {
          return false;
        }
      }
      return false;
    },
    handleActionPermissionCode() {
      if (
        !this.$has(this.PW_Worker_Portal_Code.Training.Accept) &&
        !this.$has(this.PW_Worker_Portal_Code.Training.SignUp) &&
        !this.$has(this.PW_Worker_Portal_Code.Training.Reschedule) &&
        !this.$has(this.PW_Worker_Portal_Code.Training.Decline) &&
        !this.$has(this.PW_Worker_Portal_Code.Training.Withdraw) &&
        !this.$has(this.PW_Worker_Portal_Code.Training.ViewCourseInfo)
      ) {
        this.hasAction = false;
      } else {
        this.hasAction = true;
      }
    },
    handleTimes(times) {
      if (!times || times?.length === 0) {
        return [""];
      }
      return times;
    },
    initData() {
      isNoClassData().then((res) => {
        this.isNoData = !res.data.info;
      });
      getPortalPageSetting(pageNameEnum.TrainingClassNoData).then((res) => {
        this.noDataStr = res.data?.description;
      });
      getPortalPageSetting(pageNameEnum.TrainingClassList).then((res) => {
        this.SettingMsg = res.data?.description;
      });
    },
    handleRow(item) {
      if (!item.eleClassDetailId) {
        item._rowVariant = "warning";
      }
      return item.description;
    },
    handleButtonName(value) {
      if (value) {
        return this.buttonNames.get(parseInt(value));
      }
      return "";
    },
    clickButton(button, item, type) {
      this.selectItem = item;
      this.selectParams = {
        button,
        eleclassTypeId: item.eleClassTypeId,
        electionId: item.electionId,
        eleClassDetailId: item.eleClassDetailId,
      };
      switch (parseInt(button.name)) {
        case EnumButtonDisplay.IsShowAccept:
        case EnumButtonDisplay.IsShowReject:
        case EnumButtonDisplay.IsShowWithdraw:
          this.curFunc =
            (button.name === EnumButtonDisplay.IsShowAccept &&
              this.acceptFunc) ||
            (button.name === EnumButtonDisplay.IsShowReject &&
              this.rejectFunc) ||
            (button.name === EnumButtonDisplay.IsShowWithdraw &&
              this.withdrawFunc) ||
            null;
          this.confirmStatus =
            button.name != EnumButtonDisplay.IsShowWithdraw
              ? button.name
              : EnumTrainingConfirmStatus.Withdraw;
          if (!this.curFunc.reasonsRequired) {
            const msg = this.$g("selectReasonConfirm").replace(
              "{type}",
              this.curFunc.title.toLowerCase()
            );
            this.$bvModal
              .msgBoxConfirm(msg, {
                title: this.$g("const.confirm.confirm"),
                cancelTitle: this.$g("const.confirm.yes"),
                okTitle: this.$g("const.confirm.no"),
                cancelVariant: "primary",
                okVariant: "secondary",
                noCloseOnBackdrop: true,
              })
              .then((value) => {
                if (value != null && !value) {
                  this.setCourseCommon();
                }
              });
          } else {
            this.$bvModal.show("selectReason");
          }
          break;
        case EnumButtonDisplay.IsReschedule:
        case EnumButtonDisplay.IsSignUp:
          this.coursesType = type;
          this.isSignUp = button.name == EnumButtonDisplay.IsSignUp;
          this.$bvModal.show("modal-trainingClass");
          break;
        case EnumButtonDisplay.IsViewCourseInfo:
          this.goView(item);
          break;
        default:
          break;
      }
    },
    setCourseCommon(reason) {
      let params = {
        eleClassDetailId: this.selectItem.eleClassDetailId,
        confirmStatus: this.confirmStatus,
      };
      if (reason) {
        params.reasonComment = reason.reasonComment;
        params.reasonId = reason.reasonId;
      }
      setCoursesStatus(params).then((res) => {
        this.$refs.selectableTable1.refresh();
        this.$refs.selectableTable2.refresh();
        this.$refs.lblMsg.message(res.data.status, res.data.info);
      });
    },
    selectReasonComplete() {
      let params = {};
      if (this.curFunc.reasonsFree) {
        params.reasonComment = this.$refs.selectReason?.getReason();
      } else {
        params.reasonId = this.$refs.selectReason?.getReasonId();
      }
      this.setCourseCommon(params);
    },
    async onSelectReasonBeforeClose() {
      let valid = await this.$refs.selectReason.checkValid();
      if (valid) {
        const msg = this.$g("selectReasonConfirm").replace(
          "{type}",
          this.curFunc.title.toLowerCase()
        );
        return this.$bvModal
          .msgBoxConfirm(msg, {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.yes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          })
          .then((value) => {
            return value != null && !value;
          });
      }
      return false;
    },
    getCondition(isRequire) {
      return {
        pollId: "",
        isRequired: isRequire,
      };
    },
    goView(item) {
      this.$router.push({
        name: "courseInfo",
        query: {
          id: item.eleClassId,
        },
      });
    },
    onFieldsGet(fields) {
      this.fields = fields;
    },
    showAlert() {
      let status = saveAlert.savedStatus(this.$route);
      if (status == repResult.success) {
        this.$refs.lblMsg.message(status, saveAlert.savedMessage(this.$route));
      }
    },
    getOptions() {
      getAcceptConfig().then((data) => {
        this.acceptFunc = data.data.info;
      });
      getDeclineConfig().then((data) => {
        this.rejectFunc = data.data.info;
      });
      getWithdrawConfig().then((data) => {
        this.withdrawFunc = data.data.info;
      });
    },
    allGet1(data) {
      this.tableWapData1 = data;
    },
    allGet2(data) {
      this.tableWapData2 = data;
    },
    classType(e) {
      switch (e) {
        case 1:
          return "featuredFont";
        case 2:
          return "utilityFont";
        case 3:
          return "eventFont";
        case 4:
          return "utilityFont";
        case 5:
          return "eventFont";
        case 6:
          return "featuredFont";
        default:
          return "eventFont";
      }
    },
  },
  mounted() {
    this.handleActionPermissionCode();
    this.getOptions();
    this.showAlert();
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .hand:hover {
    cursor: pointer;
  }
  .trainBtn {
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    white-space: nowrap;
    text-align: left;
    &:hover {
      text-decoration: underline;
      color: initial;
      filter: brightness(0.8);
    }
  }
  .table-warning td {
    background: #fae99b;
    border-color: #dee2e6;
  }
  #tooltipTraining {
    --iconTooltipBackground: #ffffff;
    --iconTooltipFont: #333333;
    .tooltip-inner {
      max-width: 500px;
      padding: 10px;
      border: 1px solid #cccccc;
      box-shadow: 0px 0px 7px 2px rgba(74, 74, 74, 0.1);
    }
    .arrow::after {
      content: "";
      padding: 0.4rem;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-right-color: transparent;
      border-bottom-color: transparent;
      display: inline-block;
      transform: rotate(45deg);
      z-index: 9999;
      top: -1px;
      left: 0;
      position: relative;
    }
  }
}
.lineClamp {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  -webkit-line-clamp: 3;
}
</style>
