<template>
  <k-popwindow
    id="modal-trainingClass"
    @ok="onOK"
    :check-selected="true"
    :before-close="onBeforeClose"
    :title="$g('addTrainingClass')"
    @show="initFields"
    @hide="initName"
    @cancel="initName"
  >
    <div>
      <div class="wapModel layoutTableCard">
        <div
          v-for="(item, index) in wapTableData"
          :key="item.eleClassId"
          class="tableGroup"
        >
          <div class="tableList" v-for="(item2, index) in fields" :key="index">
            <div class="control-field">{{ item2.label }}:&nbsp;</div>
            <div class="control-value">
              <template v-if="Array.isArray(item[item2.key])">
                <div
                  v-for="(item, index) in item[item2.key]"
                  :key="index"
                  class="pl-3"
                >
                  {{ item }}
                </div>
              </template>
              <template v-else>
                {{ item[item2.key] }}
              </template>
            </div>
          </div>
          <div class="text-right">
            <b-icon
              icon="check-circle-fill"
              style="color: #aaaaaa; cursor: pointer"
              font-scale="2"
              :class="{ isSelect: index == selectIndex }"
              @click="selectCheckbox(index, selectIndex, item)"
            />
          </div>
        </div>
      </div>
      <konn-grid
        ref="selectableTableModal"
        :data-source="dataSource"
        :fields="fields"
        select-mode="single"
        :get-condition="getCondition"
        :auto-bind="false"
        :grid-sort-by="sortBy"
        :grid-dort-desc="sortDesc"
        :has-action="false"
        sort-icon-left
        @items-get="allGet"
        @row-selected="rowSelected"
        class="wapTableHide"
        :show-all-checkbox="false"
        id-field="eleClassId"
      >
        <template #cell(trainingDateTime)="row">
          <template v-if="row.value.length > 0">
            <div v-for="(item, index) in row.value" :key="index">
              {{ item }}
            </div>
          </template>
          <span v-else />
        </template>
      </konn-grid>
    </div>
  </k-popwindow>
</template>

<script>
import {
  getSignUpTrainingClassPage,
  getRescheduleTrainingClassPage,
  addSignUpClass,
  addRescheduleClass,
} from "@/api/training";
import { EnumButtonDisplay } from "@/utils/enums/enumTraining";
import { PW_Worker_Portal_Code } from "../../../router/permissionCode";

export default {
  props: {
    refs: {
      type: Object,
      default() {
        return null;
      },
    },
    params: {
      type: Object,
      default() {
        return null;
      },
    },
    coursesType: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
  data() {
    return {
      fields: [],
      sortBy: "",
      sortDesc: false,
      dataSource: null,
      addFunc: null,
      wapTableData: null,
      recordSelect: null,
      selectIndex: null,
      PW_Worker_Portal_Code,
    };
  },
  watch: {
    params(val) {
      if (
        this.coursesType == 1 ||
        this.$has(this.PW_Worker_Portal_Code.Training.CanSelectOptionalClass)
      ) {
        if (val) {
          let isSignUp = val.button.name == EnumButtonDisplay.IsSignUp;
          if (isSignUp) {
            this.dataSource = getSignUpTrainingClassPage;
            this.addFunc = addSignUpClass;
          } else {
            this.dataSource = getRescheduleTrainingClassPage;
            this.addFunc = addRescheduleClass;
          }
          this.$nextTick(() => {
            if (this.$refs.selectableTableModal) {
              this.$refs.selectableTableModal.refresh();
            }
          });
        }
      }
    },
  },
  methods: {
    initFields() {
      this.fields = [
        { key: "trainingSection", label: "trainingSection" },
        { key: "trainingType", label: "trainingType" },
        { key: "trainingLocation", label: "trainingLocation" },
        { key: "trainingDateTime", label: "trainingDateTime" },
      ];
      this.selectIndex = null;
    },
    initName() {
      //this.fields = [];
    },
    onOK() {
      let userIds =
        this.$refs.selectableTableModal.getSelectedIds("eleClassId");
      let params = null;
      if (this.isSignUp) {
        params = {
          eleClassId: userIds[0],
          electionId: this.params.electionId,
        };
      } else {
        params = {
          oldEleClassDetailId: this.params.eleClassDetailId,
          eleClassId: userIds[0],
          electionId: this.params.electionId,
        };
      }
      console.log(params)
      this.addFunc(params).then((m) => {
        this.refs.lblMsg.message(m.data.status, m.message);
        this.refs.selectableTable1.pageChange(1);
        this.refs.selectableTable2.pageChange(1);
      });
    },
    onBeforeClose() {
      return this.$bvModal
        .msgBoxConfirm(this.$g("const.confirm.save"), {
          title: this.$g("const.confirm.confirm"),
          cancelTitle: this.$g("const.confirm.saveYes"),
          okTitle: this.$g("const.confirm.no"),
          cancelVariant: "primary",
          okVariant: "secondary",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          return value != null && !value;
        })
        .catch((err) => {
          return false;
        });
    },
    getCondition() {
      return {
        eleclassTypeId: this.params.eleclassTypeId,
        electionId: this.params.electionId,
        oldEleClassDetailId: this.params.eleClassDetailId,
      };
    },
    allGet(e) {
      this.wapTableData = e;
    },
    rowSelected() {
      this.selectIndex = null;
      this.recordSelect =
        this.$refs.selectableTableModal.$refs.selectableTable.selectedRows;
      if (this.recordSelect) {
        for (let i = 0; i < this.recordSelect.length; i++) {
          if (this.recordSelect[i]) {
            this.selectIndex = i;
          }
        }
      }
    },
    selectCheckbox(e, f, g) {
      if (e == f) {
        this.$refs.selectableTableModal.selectRecord({ index: e, item: g }, false);
        this.rowSelected();
      } else {
        this.$refs.selectableTableModal.selectRecord({ index: e, item: g }, true);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.isSelect {
  color: #008000 !important;
}
</style>
