<template>
  <div class="wapModel layoutTableCard p-0" style="background: none">
    <div
      v-for="item in items"
      :key="item.id"
      class="tableGroup"
      style="padding: 15px 0"
    >
      <div style="padding: 0 10px">
        <div class="tableList justify-content-between font-weight-bold">
          {{ item.electionTitle }}
        </div>
        <div class="tableList" aria-label="Position">
          <div class="control-field normal">{{ fields[1].label }}:&nbsp;</div>
          <div class="control-value">
            <div v-for="(item, index) in item['positions']" :key="index">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="layoutTableCard d-flex flex-wrap justify-content-between"
        :class="!item.eleClassDetailId ? 'groupWarin' : ''"
      >
        <div>
          <div class="tableList" aria-label="Class Type">
            <div class="control-field normal">{{ fields[2].label }}:&nbsp;</div>
            <div class="control-value">
              {{ item.classType }}
            </div>
          </div>
          <div
            class="tableList"
            aria-label="Training Section"
            v-if="item.eleClassDetailId"
          >
            <div class="control-field normal">{{ fields[4].label }}:&nbsp;</div>
            <div class="control-value">
              <template
                v-if="
                  $has(PW_Worker_Portal_Code.Training.CanSelectOptionalClass)
                "
              >
                <k-link @click="goView(item)">
                  {{ item.trainingSection }}</k-link
                >
              </template>
              <template v-else>{{ item.trainingSection }}</template>
            </div>
          </div>
          <div
            class="tableList"
            aria-label="Training Dates/Times"
            v-if="item.eleClassDetailId && item.trainingTypeInt != 2"
          >
            <div class="control-field normal">{{ fields[6].label }}:&nbsp;</div>
            <div class="control-value pl-3">
              <div
                v-for="(item, index) in item['trainingDatesTimes']"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div
            class="tableList"
            v-if="item.trainingTypeInt == 2"
            style="color: #f59a23"
          >
            {{ item.trainingType }}
          </div>
          <div class="tableList mt-3">
            <k-button
              @click="clickButton(button, item)"
              v-for="button in item.buttonDisplay"
              v-show="button.visible && handleButtonPermissionCode(button.name)"
              :key="button.name"
              class="mr-2 mb-2"
              :variant="classType(button.name)"
            >
              {{ handleButtonName(button.name) }}
            </k-button>
          </div>
        </div>
        <b-icon
          v-if="item.completedAttended.toLowerCase() == 'yes'"
          icon="check-circle-fill"
          style="color: var(--featuredFont); cursor: auto; margin-top: 1rem"
          font-scale="2"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { PW_Worker_Portal_Code } from "../../../router/permissionCode";
export default {
  props: {
    fields: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    handleTimes: {
      type: Function,
      default: null,
    },
    goView: {
      type: Function,
      default: null,
    },
    clickButton: {
      type: Function,
      default: null,
    },
    handleButtonName: {
      type: Function,
      default: null,
    },
    handleButtonPermissionCode: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      PW_Worker_Portal_Code,
    };
  },
  methods: {
    classType(e) {
      switch (e) {
        case 1:
          return "primary";
        case 2:
          return "utility";
        case 3:
          return "featured";
        case 4:
          return "utility";
        case 5:
          return "featured";
        case 6:
          return "primary";
        default:
          return "featured";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.groupWarin {
  background: #fae99b;
}
.normal {
  font-weight: normal;
}
</style>